import React from 'react';
import './Header.css';

const header = () => {
    return (
        <div className="app-header">
            {/* <div>Spare Parts</div> */}
        </div>
    )
}

export default header;