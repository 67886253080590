export const BASE_URL = 'https://web.accountsdeck.com/ws/spd/';

let myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("API_TOKEN", "STBW7FSRNIQEHTYCA11XR");

export let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow'
};